<template>
  <div class="container">
		<div class=" row align-items-center">
			<div class="col-md-3"></div>

			<div class="col-md-6 main-content">
				<div class="card reset-password">
					<div class="content-holder">
					  <div class="card-header">
					  	<p>{{ $t('views.forms.new_password') }}</p>
					  </div>
					  <div class="card-body">
              <div v-if="requestError" class="status-mssg" >{{ responseMssg }}</div>
					  	<p>{{ $t('views.forms.new_password_body') }}</p>
						<div>
              <input type="password" id="input-pwd" class="form-control mb-2 text-field" :placeholder="$t('views.forms.place_holders.password')" v-model="password" name="Password" v-validate="'required'" ref="password">
              <span toggle="#password-field" :class="eyeClassP" @click="togglePassword()"></span>
            </div>
            <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first('Password') }}</div>

            <div class="confirmation-input-field">
              <input type="password" id="input-confirm-pwd" class="form-control mb-2 text-field" :placeholder="$t('views.forms.place_holders.confirm_password')" v-model="passwordConfirmation" name="ConfirmPassword" v-validate="'required|confirmed:password'">
              <span toggle="#confirm-password-field" :class="eyeClassC" @click="toggleConfirmPassword()"></span>
            </div>
            <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first('ConfirmPassword') }}</div>

					  </div>
					  <div class="card-footer">
              <button class="btn btn-block" @click="validateForm()">{{ $t('views.forms.place_holders.set_password') }}</button>
					  </div>
					</div>
				</div>
			</div>

			<div class="col-md-3"></div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'newPassword',
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      submitted: false,
      responseMssg: '',
      requestError: false,
      eyeClassP: 'fa fa-fw fa-eye field-icon toggle-password',
      eyeClassC: 'fa fa-fw fa-eye field-icon toggle-password'
    };
  },
  methods: {
    togglePassword(){
      if(this.eyeClassP.includes('fa-eye-slash')){
        this.eyeClassP=this.eyeClassP.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-pwd').type='password';
      }else{
        this.eyeClassP=this.eyeClassP.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-pwd').type='text';
      }
    },
    toggleConfirmPassword(){
      if(this.eyeClassC.includes('fa-eye-slash')){
        this.eyeClassC=this.eyeClassC.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-confirm-pwd').type='password';
      }else{
        this.eyeClassC=this.eyeClassC.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-confirm-pwd').type='text';
      }
    },
  	validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.setNewPassword();
        }
      });
    },
    setNewPassword() {
      const form = new FormData();
      form.append('email', this.$route.query.email);
      form.append('token', this.$route.query.t);
      form.append('password', this.password);

      this.$http.post('/update-password', form)
        .then(() => {
          this.$router.push('/login?resetPassword=true');
        })
        .catch(() => {
          this.responseMssg = this.$t('views.forms.responses.token_expired');
          this.requestError = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>


  .container {
  	margin-top: $loginMarginTop;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 160px);
  }

  .content-holder {
  	width: 90%;
  	margin: auto;
  }

  .card-header {
  	color: $secondaryColor;
  	font-size: 42px;
  	font-weight: 300;
    letter-spacing: 1.8px;
    margin-top: 8% !important;
    margin-bottom: 0% !important;
    border: none;

  }

  .card-body {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    padding-right: 16px !important;
  }

  .card-body p {
    margin-bottom: 10% !important;
  }

  .confirmation-input-field {
    margin-top: 20px !important;
  }

  .card-footer {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    padding-right: 16px !important;
    border: none;
  }

  .card-body p {
    color: white;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.49px;
  }

  .main-content {
    padding: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-body input {
    border-color: #FFFFFF;
    border-radius: 0%;
    background-color: rgba(80, 80, 80, 0.9);
    color: white;
    color: white;
    opacity: 0.5;
  }

  .reset-password {
  	background-color: rgba(80, 80, 80, 0.9);
  }

  .card-footer .btn {
    color: black;
    background-color: white;
    height: 37px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    padding-left: 7%;
    padding-top: 1.5%;
    text-transform: none;
    border-radius: 0 !important;

    &:hover{
      background-color: $secondaryColor;
    }
  }

  .toggle-password:hover {
    cursor: pointer;
  }

  .field-icon {
    float: right;
    margin-right: 10px;
    margin-top: -35px;;
    position: relative;
    z-index: 2;
    color: white;
    opacity: 0.8;
  }

  .error-mssg {
    font-size: 12px;
    margin-top: 0px !important;
    margin-bottom: 4% !important;
  }

  .status-mssg {
    font-size: 12px;
    margin-top: 4% !important;
    margin-bottom: 4% !important;
    color: red;
  }

  @media screen and (max-device-width: 1400px) {
    header {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
  }
</style>
